
import { useStore } from "vuex";
import { defineComponent, watchEffect, ref } from "vue";

export default defineComponent({
	setup() {
        const store = useStore();
        const images = ref([]) 
        const visible = ref(true);
        const index =  ref(null);

		watchEffect(() => {
			const data = store.getters.getImageProps
			images.value = data.previewImage
			visible.value = data.visible
			index.value = data.index
		})
		
        const handleHide = () => {
           visible.value = false
		   store.commit('setImages', {});
        }
       
		return {
            images,
			visible,
			index,
			handleHide,
		};
	},
});
