``
import { defineComponent, toRefs, ref, inject, watchEffect } from "vue";

export default defineComponent({
	props: {
		value: { type: Boolean || String , default: false },
		name: { type: String, default: "" },
		options: Object,
        size:  { type: String, default: "medium" },
		required: { type: Boolean, default: false },
		target: { type: String, default: "" },
		rule: { type: String, default: "" },
		ruleMsg: { type: String, default: "Invalid data" },
		requiredMsg: { type: String, default: "This field is required" },
        callEmit: { type: Boolean, default: false },
		displayMessage: { type: String, default: "" },
	},

	setup(props, context) {
		const { required, rule, ruleMsg, requiredMsg, target, value, callEmit } = toRefs(
			props
		);
		const inputValue = ref(false);
            
		let rules: any = ref({});
		const emitter: any = inject("emitter");

		if (required.value == true) {
			let ruleValues = {};
			if (rule.value != "") {
				ruleValues = {
					type: rule.value,
					message: ruleMsg.value,
					trigger: ["change"],
				};
			}

			rules = [
				{
					required: true,
					message: requiredMsg.value,
					trigger: ["change"],
				},
				ruleValues,
			];
		}

		watchEffect(() => {
			inputValue.value = value.value
		})

		const updateValue = () => {
			context.emit(`update:${target.value}`, inputValue.value);
            if (callEmit.value == true) {
				context.emit("change", value);
			}
		};

		emitter.on("clearInput", () => {
			inputValue.value = false;
		});

		return {
			updateValue,
			inputValue,
			rules,
		};
	},
});
