import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueEasyLightbox = _resolveComponent("VueEasyLightbox")!

  return (_openBlock(), _createBlock(_component_VueEasyLightbox, {
    visible: _ctx.visible,
    imgs: _ctx.images,
    index: _ctx.index,
    onHide: _ctx.handleHide
  }, null, 8, ["visible", "imgs", "index", "onHide"]))
}