
import { defineComponent, toRefs, ref, inject, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        value: { type: String, default: '' },
        min: { type: Number, default: 0 },
        name: { type: String, default: '' },
        label: { type: String, default: '' },
        placeholder: { type: String, default: 'message.ENTER message.YOUR message.VALUE' },
        required: { type: Boolean, default: false },
        type: { type: String || Number, default: 'text' },
        target: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        rule: { type: String, default: '' },
        ruleMsg: { type: String, default: 'Invalid data' },
        requiredMsg: { type: String, default: 'message.THIS_FIELD message.IS_REQUIRED' }
    },

    setup(props, context) {
        const { target, value } = toRefs(props);
        const inputValue = ref(value.value);
        const i18n = useI18n();
        const translate = (msg: string) => {
            if (msg.includes('message.')) {
                const splitKeys = msg.split(' ');
                splitKeys.forEach((item, index, array) => {
                    array[index] = i18n.t(item);
                });
                return splitKeys.join(' ');
            }
            return msg;
        };

        const emitter: any = inject('emitter');

        watchEffect(() => {
            inputValue.value = value.value;
        });

        const updateValue = () => {
            context.emit(`update:${target.value}`, inputValue.value);
        };

        const blurValue = () => {
            context.emit('blur');
        };

        emitter.on('clearInput', () => {
            inputValue.value = '';
        });

        return {
            updateValue,
            inputValue,
            translate,
            blurValue
        };
    }
});
